///// Form helpers

function is_default_value( value ) {
  return ( 'none' === value );
}

function enable_element_and_mf_container( $el ) {
  $el.prop( 'disabled', false );
  $el.closest( 'label' )
    .removeClass( 'disabled' )
    .attr( 'aria-hidden', false );
}

function disable_element_and_mf_container( $el ) {
  $el.prop( 'disabled', true );
  $el.closest( 'label' )
    .addClass( 'disabled' )
    .attr( 'aria-hidden', true );
}

function enable_el_col( $el ) {
  var $col = $el.closest( '.row > .disabled' );

  if ( $col.length ) {
    $col.removeClass( 'disabled' );
  }
}

function disable_el_col( $el ) {
  var $col = $el.closest( '.row > div' );

  if ( $col.length ) {
    $col.addClass( 'disabled' );
  }
}


/**
 *
 * @param {jQuery Array Objects} $el
 * @param {String} direction - Next or prev. Be sure to write "prev". It directly passes the nave to the jQuery function.
 * @param {Boolean} _check_if_original_el - Automatic param to know if we need to check or give a flag to the original element. Private param that should not be given.
 * @param {jQuery Array Objects} _$original_el - We need it to reset it's data after use.
 *
 * @return {jQuery Array Objects|false} False if no good next has been found.
 */
function loop_through_el_siblings_until_not_disabled_nor_default( $el, direction, _check_if_original_el, _$original_el ) {

  // If it's the default element, we baill. Search is over and has failed.
  if ( _check_if_original_el ) {
    if ( $el.data( 'is-original-loop-el' ) ) {
      _$original_el.data( 'is-original-loop-el', false );
      return false;
    }
  } else {
    $el.data( 'is-original-loop-el', true );
    _$original_el = $el;
  }

  // Get next or prev sibling.
  if ( ! direction ) {
    direction = 'next';
  }
  var is_pos = ':last-child';
  var take_pos = ':first-child';
  if ( 'prev' === direction ) {
    is_pos = ':first-child';
    take_pos = ':last-child';
  }
  if ( $el.is( is_pos ) ) {
    $el = $el.parent().children( take_pos );
  } else {
    $el = $el[ direction ]();
  }

  // We continue the search if it's disabled or has the default value...
  if ( is_el_disabled_or_default( $el ) ) {
    return loop_through_el_siblings_until_not_disabled_nor_default( $el, direction, true, _$original_el );
  }

  // ... or it's the right element and we return it.
  _$original_el.data( 'is-original-loop-el', false );
  return $el;
}

function is_el_disabled_or_default( $el ) {
  return ( $el.prop( 'disabled' ) || $el.hasClass( 'disabled' ) || is_default_value( $el.val() ) );
}


///// MFSelect extra control

function toggle_select_class_if_default_value( $mfSelect, value ) {
  if ( is_default_value( value ) ) {
    $mfSelect.addClass( 'is-default-value' );
  } else {
    $mfSelect.removeClass( 'is-default-value' );
  }
}

function select_previous_sibling( $el ) {
  var $sibling = loop_through_el_siblings_until_not_disabled_nor_default( $el, 'prev' );

  if ( $sibling ) {
    unselect_options( $sibling.siblings() );
    select_options( $sibling );
  }

  return $sibling;
}

function select_next_sibling( $el ) {
  var $sibling = loop_through_el_siblings_until_not_disabled_nor_default( $el, 'next' );

  if ( $sibling ) {
    unselect_options( $sibling.siblings() );
    select_options( $sibling );
  }

  return $sibling;
}

function get_option_link_class( $option ) {
  var value = ( $option.is( 'option' ) ) ? $option.val() : $option.data( 'value' );

  var show_value = $option.data( 'show-trigger' );
  if ( ! show_value ) {
    value = 'none';
  }

  return '.option--' + value + '-' + show_value;
}

function select_options( $options ) {
  var $mfSelect = $options.closest( '.mfSelect' );

  $options.each( function () {
    var $this_option = $( this );
    var $option_selected = $mfSelect.find( '.dropdown ' + get_option_link_class( $this_option ) );

    $this_option
    .prop( 'selected', true )
    .addClass( 'selected' );

    if ( $option_selected.length ) {
      $option_selected.addClass( 'selected' );
      $mfSelect.find( '.not-buggy-value' ).text( $option_selected.text() );
    }
  });
}

function return_mfSelect_to_default( $mfSelect ) {
  var $value_container = $mfSelect.find( '.not-buggy-value' );

  $value_container.text( $mfSelect.find( '.option--none' ).text() );

  $mfSelect.addClass( 'is-default-value' );
}

function unselect_options( $options ) {
  var $mfSelect = $options.closest( '.mfSelect' );
  var one_really_was_selected = false;

  $options.each( function () {
    var $this_option = $( this );
    var $option_selected = $mfSelect.find( '.dropdown ' + get_option_link_class( $this_option ) );

    if ( $this_option.hasClass( 'selected' ) ) {
      one_really_was_selected = true;

      $this_option
        .prop( 'selected', false )
        .removeClass( 'selected' );
    }


    if ( $option_selected.length ) {
      $option_selected.removeClass( 'selected' );
    }
  });

  if ( one_really_was_selected ) {
    return_mfSelect_to_default( $mfSelect );
  }
}

function show_option( $option ) {
  var $mfSelect = $option.closest( '.mfSelect' );
  var $linked_option = $mfSelect.find( get_option_link_class( $option ) );

  $option.show();
  if ( $linked_option.length ) {
    $linked_option
      .prop( 'disabled', false )
      .show();
  }
}

function hide_and_unselect_option( $option ) {
  var $mfSelect = $option.closest( '.mfSelect' );
  var $linked_option = $mfSelect.find( get_option_link_class( $option ) );

  unselect_options( $option );

  $option.hide();
  if ( $linked_option.length ) {
    $linked_option
      .prop( 'disabled', true )
      .hide();
  }
}

function check_show_hide_logic_for_options_of_receiver_by_value( $receiver, value ) {
  $receiver.find( 'option' ).each( function () {
    var $option = $( this );
    var value_needed_to_show = $option.data( 'show-trigger' );

    if ( value_needed_to_show === value ) {
      show_option( $option );

      if ( true === $receiver.prop( 'disabled' ) ) {
        enable_select( $receiver );
      }
    } else {
      hide_and_unselect_option( $option );
    }
  });
}

function get_select_receiver_from_trigger( $select_trigger ) {
  return $( '[data-show-option-by-parent-from="#' + $select_trigger.attr( 'id' ) + '"]' );
}

function check_show_hide_logic_from_changed_select_by_value( $trigger, value ) {
  var $receiver = get_select_receiver_from_trigger( $trigger );

  if ( $receiver.length ) {
    check_show_hide_logic_for_options_of_receiver_by_value( $receiver, value );
  }

  resolve_enable_link();
}

function find_and_disable_linked_select( $select ) {
  var $receiver = get_select_receiver_from_trigger( $select );

  disable_element_and_mf_container( $receiver );
  disable_el_col( $receiver );
}

function enable_select( $select ) {
  enable_element_and_mf_container( $select );
  enable_el_col( $select );
}

function init_mfselect_extra_control() {
  var $mfSelect = $( '.mfSelect' );

  if( ! $mfSelect.length ) {
    return;
  }


  $mfSelect.each( function () {
    var $mfSelect = $( this );
    var $select = $mfSelect.find( 'select' );
    var $value_container = $mfSelect.find( '.value' );

    if ( is_default_value( $mfSelect.find( 'select' ).val() ) ) {
      toggle_select_class_if_default_value( $mfSelect, $mfSelect.find( 'select' ).val() );
      find_and_disable_linked_select( $select );
    }

    // Set our own value container.
    $( '<div class="not-buggy-value">' + $value_container.text() + '</div>' ).insertAfter( $value_container );
  });


  $mfSelect.find( '.not-buggy-value' ).on( 'click', function () {
    var $not_buggy_value = $( this );

    $not_buggy_value.prev().trigger( 'click' );
    $not_buggy_value.siblings( 'select' ).focus();
  });

  $mfSelect.on( 'keydown', function ( event ) {
    var $select = $( event.target );
    var $mfSelect = $select.closest( '.mfSelect' );
    var $option_selected = $select.children( ':selected' );

    if ( ! $option_selected.length ) {
      $option_selected = $select.children( '.selected' );
    }

    if ( ! $option_selected.length ) {
      $option_selected = $select.children( ':first-child' );
    }

    if ( $option_selected.length ) {
      // UP
      if ( 38 === event.keyCode ) {
        $option_selected = select_previous_sibling( $option_selected );
      }
      // DOWN
      else if ( 40 === event.keyCode ) {
        if ( $mfSelect.hasClass( 'show' ) ) {
          $option_selected = select_next_sibling( $option_selected );
        }
      }
      // SPACE
      else if ( 32 === event.keyCode ) {
        if ( $mfSelect.hasClass( 'show' ) ) {
          $mfSelect.removeClass( 'show focus' );
        } else {
          $mfSelect.addClass( 'show focus' );
        }
      }

      toggle_select_class_if_default_value( $mfSelect, $option_selected.val() );

      check_show_hide_logic_from_changed_select_by_value( $select, $option_selected.val() );
    }
  });

  $mfSelect.find( '.option' ).on( 'click', function () {
    var $option = $( this );
    var option_label = $option.text();
    var option_value = $option.data( 'value' );
    var $mfSelect = $option.closest( '.mfSelect' );
    var $select = $mfSelect.find( 'select' );
    var $real_option_selected = $mfSelect.find( 'select ' + get_option_link_class( $option ) );


    if ( $real_option_selected.length ) {
      $option.addClass( 'selected' );

      unselect_options( $real_option_selected.siblings() );
      select_options( $real_option_selected );

      $mfSelect.find( '.not-buggy-value' ).html( option_label );

      toggle_select_class_if_default_value( $mfSelect, option_value );

      check_show_hide_logic_from_changed_select_by_value( $select, option_value );
    }
  });
}


///// Input enable control
function resolve_enable_link() {
  $( '[data-enable-link]' ).each( function () {
    var $input = $( this );
    var selector = $input.data( 'enable-link' );
    var may_enable = true;

    if ( selector ) {
      $( selector ).each( function () {
        if ( ! may_enable ) {
          return false;
        }

        var $this = $( this );
        var value = false;

        if ( $this.is( 'select' ) ) {
          value = $this.find( ':selected' ).val();
        } else {
          value = $this.val();
        }

        if ( ! value || is_default_value( value ) ) {
          return may_enable = false;
        }
      });

      if ( may_enable ) {
        enable_element_and_mf_container( $input );
        enable_el_col( $input );
      } else {
        disable_element_and_mf_container( $input );
        disable_el_col( $input );
      }
    }
  });
}


///// ON READY
$( function () {
  init_mfselect_extra_control();
  resolve_enable_link();
});