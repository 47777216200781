
/* Stick up menus
 ========================================================*/
(function ($) {
  var o = $('html');
  if (o.hasClass('desktop')) {
    $(document).ready(function () {
      $('.stuck_container').TMStickUp({});
    });
  }
})(jQuery);

/* ToTop
 ========================================================*/
(function ($) {
  var o = $('html');
  if (o.hasClass('desktop')) {
    $(document).ready(function () {
      $().UItoTop({
        easingType: 'easeOutQuart',
        containerClass: 'toTop fa-angle-up',
      });
    });
  }
})(jQuery);

/* QuickContact
 ========================================================*/
(function ($) {
  $(document).ready(function () {
    var o = $('.quick-contact');

    if (o.length) {
      function open_quick_contact($quick_contact, $toggle) {
        if (!$toggle) {
          $toggle = $quick_contact.find('.quick-contact__toggle');
        }

        $quick_contact.addClass('opened');
        $toggle.attr('aria-expanded', true);

        /**
         * Finalement, ne pas donner le focus. Vue que les placeholders sont invisible quand le focus est dedans, c'est vraimewnt pas adccessible.
         */
        // var first_input = $quick_contact.find( 'label' ).first();
        // first_input.focus();
        // first_input.addClass( 'focused' );

        init_close_events();
      }

      function close_quick_contact($quick_contact, $toggle) {
        if (!$toggle) {
          $toggle = $quick_contact.find('.quick-contact__toggle');
        }

        $quick_contact.removeClass('opened');
        $toggle.attr('aria-expanded', false);
        $(window).off('click.quick_contact').off('keydown.quick_contact');
      }

      function try_closing_by_event(event) {
        var $target = $(event.target);
        if (!$target.closest('.quick-contact').length) {
          close_quick_contact($('.quick-contact.opened'));
        }
      }

      function init_close_events() {
        $(window)
          .on('click.quick_contact', function (event) {
            try_closing_by_event(event);
          })
          .on('keydown.quick_contact', function (event) {
            // ESCAPE
            if (27 === event.keyCode) {
              close_quick_contact($('.quick-contact.opened'));
            }
          });
      }

      o.each(function () {
        var $quick_contact = $(this);

        $quick_contact
          .find('.quick-contact__toggle')
          .on('click', function (event) {
            event.stopPropagation();

            var $toggle = $(this);
            var $modal = $toggle.closest('.quick-contact');

            if ($modal.hasClass('opened')) {
              close_quick_contact($modal, $toggle);
            } else {
              open_quick_contact($modal, $toggle);
            }
          });
      });
    }
  });
})(jQuery);


/* TouchTouch Gallery
 ========================================================*/
(function ($) {
  var o = $('.thumb');
  if (o.length > 0) {
    $(document).ready(function () {
      o.touchTouch();
    });
  }
})(jQuery);

/* Mailform
 =============================================*/
(function ($) {
  var $form = $('.rd-mailform');
  var $form2 = $('.rd-mailform-2');
  if ($form.length || $form2.length) {
    $(document).ready(function () {
      if ($form.length) {
        $form.rdMailForm(
          {
            validator: {
              constraints: {
                '@LettersOnly': {
                  message: 'Please use letters only!',
                },
                '@NumbersOnly': {
                  message: 'Please use numbers only!',
                },
                '@NotEmpty': {
                  message: 'Field should not be empty!',
                },
                '@Email': {
                  message: 'Enter valid e-mail address!',
                },
                '@Phone': {
                  message: 'Enter valid phone number!',
                },
                '@Date': {
                  message: 'Use DD/MM/YYYY format!',
                },
                '@SelectRequired': {
                  message: 'Please choose an option!',
                },
              },
            },
          },
          {
            MF000: 'Sent',
            MF001: 'Recipients are not set!',
            MF002: 'Form will not work locally!',
            MF003: 'Please, define email field in your form!',
            MF004: 'Please, define type of your form!',
            MF010: 'Veuillez cocher le reCaptcha.',
            MF011: 'Vous êtes un robot selon Google.',
            MF012: "Une erreur s'est produite. Veuillez réessayer plus tard.",
            MF254: 'Something went wrong with PHPMailer!',
            MF255: 'There was an error submitting the form!',
          }
        );
      }

      if ($form2.length) {
        $form.rdMailForm(
          {
            validator: {
              constraints: {
                '@LettersOnly': {
                  message: 'Please use letters only!',
                },
                '@NumbersOnly': {
                  message: 'Please use numbers only!',
                },
                '@NotEmpty': {
                  message: 'Field should not be empty!',
                },
                '@Email': {
                  message: 'Enter valid e-mail address!',
                },
                '@Phone': {
                  message: 'Enter valid phone number!',
                },
                '@Date': {
                  message: 'Use DD/MM/YYYY format!',
                },
                '@SelectRequired': {
                  message: 'Please choose an option!',
                },
              },
            },
          },
          {
            MF000: 'Sent',
            MF001: 'Recipients are not set!',
            MF002: 'Form will not work locally!',
            MF003: 'Please, define email field in your form!',
            MF004: 'Please, define type of your form!',
            MF010: 'Veuillez cocher le reCaptcha.',
            MF011: 'Vous êtes un robot selon Google.',
            MF012: "Une erreur s'est produite. Veuillez réessayer plus tard.",
            MF254: 'Something went wrong with PHPMailer!',
            MF255: 'There was an error submitting the form!',
          }
        );
      }
    });
  }
})(jQuery);

/* Orientation tablet fix
 ========================================================*/
$(function () {
  // IPad/IPhone
  var viewportmeta =
      document.querySelector && document.querySelector('meta[name="viewport"]'),
    ua = navigator.userAgent,
    gestureStart = function () {
      viewportmeta.content =
        'width=device-width, minimum-scale=0.25, maximum-scale=1.6, initial-scale=1.0';
    },
    scaleFix = function () {
      if (viewportmeta && /iPhone|iPad/.test(ua) && !/Opera Mini/.test(ua)) {
        viewportmeta.content =
          'width=device-width, minimum-scale=1.0, maximum-scale=1.0';
        document.addEventListener('gesturestart', gestureStart, false);
      }
    };

  scaleFix();
  // Menu Android
  if (window.orientation != undefined) {
    var regM = /ipod|ipad|iphone/gi,
      result = ua.match(regM);
    if (!result) {
      $('.sf-menus li').each(function () {
        if ($('>ul', this)[0]) {
          $('>a', this).toggle(
            function () {
              return false;
            },
            function () {
              window.location.href = $(this).attr('href');
            }
          );
        }
      });
    }
  }
});

(function($) {

  //when dom is ready
  $(document).ready(function() {

      // on scroll Navbar Fixed and back to top show
      $(window).on('scroll', function() {
          if($(window).width() > 600){
              if ($(window).scrollTop() > 600) {
                  $('#header').addClass('navbar-fixed-top');
                  $('#back-to-top').addClass('reveal');
              } else {
                  $('#header').removeClass('navbar-fixed-top');
                  $('#back-to-top').removeClass('reveal');
              }
          }
      });


      // Start Back to Top
      $('#back-to-top').on('click', function() {
          $("html, body").animate({scrollTop: 0}, 1000);
          return false;
      });

      // Styles Switcher
      $(document).ready(function(){
          $('.open-switcher').click(function(){
              if($(this).hasClass('show-switcher')) {
                  $('.switcher-box').css({'left': 0});
                  $('.open-switcher').removeClass('show-switcher');
                  $('.open-switcher').addClass('hide-switcher');
              }else if(jQuery(this).hasClass('hide-switcher')) {
                  $('.switcher-box').css({'left': '-212px'});
                  $('.open-switcher').removeClass('hide-switcher');
                  $('.open-switcher').addClass('show-switcher');
              }
          });
      });


      //Layout Switcher
      $(".layout-style").change(function(){
          if( $(this).val() == 1){
              $("#container").removeClass("boxed-page"),
              $(window).resize();
          } else{
              $("#container").addClass("boxed-page"),
              $(window).resize();
          }
      });

      //Background Switcher
      $('.switcher-box .bg-list li a').click(function() {
          var current = $('.switcher-box select[id=layout-style]').find('option:selected').val();
          if(current == '2') {
              var bg = $(this).css("backgroundImage");
              $("body").css("backgroundImage",bg);
          } else {
              alert('Please select boxed layout');
          }
      });
  });
  //dom ready end

})(jQuery);
